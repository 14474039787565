import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import showdown from 'showdown'
import Image from '../Helpers/image'

import './workersBlock.scss'

const MapBlock = () => (
    <StaticQuery
        query={graphql`
        {
            markdownRemark(fileAbsolutePath: {regex: "/(home/_index)/"}) {
                frontmatter {
                    column_workers {
                        body
                        title
                        image
                        link {
                            link_href
                            link_title
                        }
                    }
                }
            }
        }
        `}
        render={ (data) => {
            const ColumnWorkersData = data.markdownRemark.frontmatter.column_workers
            const converter = new showdown.Converter()

            return(
                <div className="worker-block">
                    <Fade bottom cascade>
                        <div className="worker-block__text">
                            <h3>{ ColumnWorkersData.title }</h3>
                            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(ColumnWorkersData.body) }} />
                            <div className="worker-block__link">
                                    <Link to={ ColumnWorkersData.link.link_href }
                                        className="special-link">
                                            { ColumnWorkersData.link.link_title }
                                    </Link>
                            </div>
                        </div>

                        <div className="worker-block__image">
                            <Image filename={ ColumnWorkersData.image } />
                        </div>
                    </Fade>
                </div>
            )
        }}
  ></StaticQuery>
)

export default MapBlock
