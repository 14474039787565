import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Svg = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {extension: {regex: "/(svg)/"}}) {
          edges {
            node {
              relativePath
              publicURL
            }
          }
        }
      }
    `}

    render={(data) => {
      
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename.split('/').pop());
      });

      if (!image) { return null; }
        return (
          <img
            className="lazyload"
            alt={ props.alt }
            src={ image.node.publicURL }  />
        );
    }}
  />
)

export default Svg
