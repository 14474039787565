import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Video from '../Helpers/video'

import './welcome-video.scss'

const WelcomeVideo = () => (
    <StaticQuery
        query={graphql`
        {
            markdownRemark(fileAbsolutePath: {regex: "/(home)/"}) {
                frontmatter {
                  box_video {
                    content
                    file
                    title
                    link {
                      link_href
                      link_title
                    }
                  }
                }
            }
        }
        `}
        render={ (data) => {
            const WelcomeData = data.markdownRemark.frontmatter.box_video

            return(
                <div className="welcome-video">
                    <Fade bottom>
                        <div className="welcome-video__video">
                            <Video filename={ WelcomeData.file } />
                        </div>
                    </Fade>

                    <Fade bottom>
                        <div className="welcome-video__content">
                            <h3>{ WelcomeData.title }</h3>
                            <p>{ WelcomeData.content }</p>

                            <div className="welcome-video__link">
                                <Link to={ WelcomeData.link.link_href }
                                    className="special-link special-link--white">{ WelcomeData.link.link_title }</Link>
                            </div>
                        </div>
                    </Fade>
                </div>
            )
        }}
  ></StaticQuery>
)

export default WelcomeVideo
