import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Svg from '../Helpers/svg'

import './columns-solutions.scss'

const ColumnsSolutions = (props) => (
    <StaticQuery
        query={graphql`
        {
            allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(diferenciais)/"}}, sort: {fields: frontmatter___order}) {
                edges {
                  node {
                    frontmatter {
                      title
                      icon
                    }
                    html
                  }
                }
            }
            markdownRemark(fileAbsolutePath: {regex: "/(home)/"}) {
                frontmatter {
                  column_left {
                    body
                    link {
                      link_href
                      link_title
                    }
                    title
                  }
                }
            }
        }
        `}
        render={ (data) => {
            const ColumnLeftData = data.markdownRemark.frontmatter.column_left
            const DiferencialsData = data.allMarkdownRemark

            return(
                <div className="columns-solutions container">
                    <Fade bottom cascade>
                        <div className="columns-solutions__title">
                            <h3>{ ColumnLeftData.title }</h3>
                            
                            <div className="columns-solutions__content">
                                <div dangerouslySetInnerHTML={{ __html: ColumnLeftData.body }} />
                                <div className="columns-solutions__link">
                                    <Link to={ ColumnLeftData.link.link_href }
                                        className="special-link">
                                            { ColumnLeftData.link.link_title }
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <div className="columns-solutions__columns">
                        <Fade bottom>
                            { DiferencialsData.edges.map((edge, index) => (
                                <div className="columns-solutions__item" key={index}>
                                    <figure>
                                        <Svg filename={ edge.node.frontmatter.icon } />                                        
                                    </figure>
                                    <h4>{ edge.node.frontmatter.title }</h4>
                                    <div className="columns-solutions__item-content"
                                        dangerouslySetInnerHTML={{ __html: edge.node.html }} >
                                    </div>
                                </div>
                            ))}
                        </Fade>
                    </div>
                </div>
            )
        }}
  ></StaticQuery>
)

export default ColumnsSolutions
