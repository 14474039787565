import React from 'react'
import { graphql } from 'gatsby'

import ColumnsSolutions from '../components/ColumnsSolutions'
import HoverColumns from '../components/HoverColumns'
import Layout from '../components/Layout'
import MapBlock from '../components/MapBlock'
import WorkersBlock from '../components/WorkersBlock'
import SEO from '../components/Helpers/seo'
import WelcomeVideo from '../components/WelcomeVideo'

export const query = graphql`
  {
    markdownRemark(fileAbsolutePath: {regex: "/(home)/"}) {
      frontmatter {
        seo {
          seo_description
          seo_title
        }
        welcome {
          welcome_content
          welcome_title
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const HomeData = data.markdownRemark.frontmatter

  return(
    <Layout>
      <SEO title={ HomeData.seo.seo_title  } description={ HomeData.seo.seo_description } />
      <WelcomeVideo />
      <ColumnsSolutions />
      <HoverColumns />
      <WorkersBlock />
      <MapBlock />
    </Layout>
  )
}

export default IndexPage
