import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import showdown from 'showdown'
import Fade from 'react-reveal/Fade'

import './hover-columns.scss'

const HoverColumns = (props) => (
    <StaticQuery
        query={graphql`
        {
          markdownRemark(fileAbsolutePath: {regex: "/(home)/"}) {
            frontmatter {
              column_hover {
                column_hover_one {
                  background
                  body
                  link {
                    link_href
                    link_title
                  }
                  title
                }
                column_hover_three {
                  background
                  body
                  link {
                    link_href
                    link_title
                  }
                  title
                }
                column_hover_two {
                  background
                  body
                  link {
                    link_href
                    link_title
                  }
                  title
                }
              }
            }
          },
          images: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
            edges {
              node {
                publicURL
                extension
                relativePath
              }
            }
          }
        }
        `}
        render={ (data) => {
            const columnData = data.markdownRemark.frontmatter.column_hover
            const converter = new showdown.Converter()

            const column_image = (filename) => {
              const source = filename.split('/').pop()
              const image = data.images.edges.find(n => {
                return n.node.relativePath.includes(source)
              })

              return image.node.publicURL
            }

            return(
                <Fade bottom cascade>
                  <div className="hover-columns">
                    <div className="hover-columns__columns"
                      style={{ backgroundImage: `url(${column_image(columnData.column_hover_one.background)})` }}>
                        <div className="hover-columns__column-container">
                            <div className="hover-columns__link">
                                <p>{ columnData.column_hover_one.title }</p>
                            </div>
          
                            <div className="hover-columns__content">
                              <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(columnData.column_hover_one.body) }} />
                              <p><Link to={ columnData.column_hover_one.link.link_href }>
                                { columnData.column_hover_one.link.link_title }</Link></p>
                            </div>
                        </div>
                    </div>

                    <div className="hover-columns__columns"
                      style={{ backgroundImage: `url(${column_image(columnData.column_hover_two.background)})` }}>
                        <div className="hover-columns__column-container">
                            <div className="hover-columns__link">
                                <p>{ columnData.column_hover_two.title }</p>
                            </div>
          
                            <div className="hover-columns__content">
                            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(columnData.column_hover_two.body) }} />
                                <p><Link to={ columnData.column_hover_two.link.link_href }>
                                { columnData.column_hover_two.link.link_title }</Link></p>
                            </div>
                        </div>
                    </div>

                    <div className="hover-columns__columns"
                      style={{ backgroundImage: `url(${column_image(columnData.column_hover_three.background)})` }}>
                        <div className="hover-columns__column-container">
                            <div className="hover-columns__link">
                                <p>{ columnData.column_hover_three.title }</p>
                            </div>
          
                            <div className="hover-columns__content">
                              <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(columnData.column_hover_three.body) }} />
                                <p><Link to={ columnData.column_hover_three.link.link_href }>
                                { columnData.column_hover_three.link.link_title }</Link></p>
                            </div>
                        </div>
                    </div>
                  </div>
                </Fade>
            )
        }}
  ></StaticQuery>
)

export default HoverColumns
