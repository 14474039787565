import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import showdown from 'showdown'

import './mapBlock.scss'
import {ReactComponent as SouthMap} from './SouthAmerica.svg'

const MapBlock = () => (
    <StaticQuery
        query={graphql`
        {
            markdownRemark(fileAbsolutePath: {regex: "/(home)/"}) {
                frontmatter {
                column_map {
                    body
                    title
                  }
                }
            }
        }
        `}
        render={ (data) => {
            const ColumnMapData = data.markdownRemark.frontmatter.column_map
            const converter = new showdown.Converter()

            return(
                <div className="map-block">
                    <Fade bottom cascade>
                        <div className="map-block__text">
                            <h3>{ ColumnMapData.title }</h3>
                            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(ColumnMapData.body) }} />
                        </div>
                    </Fade>

                    <Fade>
                        <div className="map-block__image">
                            <SouthMap />
                        </div>
                    </Fade>
                </div>
            )
        }}
  ></StaticQuery>
)

export default MapBlock
