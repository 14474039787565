import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Video = (props) => (
  <StaticQuery
    query={graphql`
      query {
        videos: allFile(filter: {extension: {regex: "/(mp4)/"}}) {
          edges {
            node {
              publicURL
              extension
              relativePath
            }
          }
        }
      }
    `}

    render={(data) => {
      
      const video = data.videos.edges.find(n => {
        return n.node.relativePath.includes(props.filename.split('/').pop());
      });

      if (!video) { return null; }

        return (
          <video autoPlay muted loop>
              <source
                  src={ video.node.publicURL }
                  type="video/mp4" />
              Your browser does not support the video tag.
          </video>
        )
    }}
  />
)

export default Video
